import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import CTA from './CTA';

import '../styles/careersList.less';

interface MarkdownRemark {
    allMarkdownRemark: {
        edges: MarkdownEdge[];
    };
}

interface MarkdownEdge {
    node: {
        id: string;
        frontmatter: {
            date: Date;
            slug: string;
            title: string;
            excerpt: string;
        };
    };
}

const CareersList = (): JSX.Element => {
    const data = useStaticQuery(graphql`
        {
            allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, filter: { frontmatter: { type: { eq: "career" } } }) {
                edges {
                    node {
                        id
                        frontmatter {
                            date(formatString: "DD.MM.YYYY")
                            slug
                            title
                            excerpt
                        }
                    }
                }
            }
        }
    `) as MarkdownRemark;
    const edges: MarkdownEdge[] = data.allMarkdownRemark.edges;
    const careersList = edges
        .filter((edge: MarkdownEdge) => !!edge.node.frontmatter.date)
        .map((edge: MarkdownEdge) => {
            const path = `/careers/${edge.node.frontmatter.slug}/`;

            return (
                <div className="item" key={edge.node.id}>
                    <h2 className="item__title">{edge.node.frontmatter.title}</h2>
                    <p className="item__excerpt">{edge.node.frontmatter.excerpt}</p>
                    <CTA classname="details" path={path} text="See details" />
                </div>
            );
        });

    return (
        <div className="careers">
            <h2 className="careers__title">Vacant positions</h2>
            <div className="careers__list">{careersList}</div>
        </div>
    );
};

export default CareersList;
