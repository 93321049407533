import * as React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import CareersList from '../components/CareersList';

const CareersPage = (): JSX.Element => {
    return (
        <>
            <Helmet title="Careers" />
            <Layout>
                <div className="page page__md">
                    <h1 className="page__title">Careers</h1>
                    <p className="page__description">Our team has been virtual for years, with people collaborating online from countries across the globe. We are constantly looking for high-end talent to join our company and excel together.</p>
                    <CareersList />
                </div>
            </Layout>
        </>
    );
};

export default CareersPage;
